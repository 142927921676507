import { GeneralObject } from 'types/index';
import {
  initSurvicate,
  getSurvicateInstance,
  ApiEvent
} from '@survicate/survicate-web-surveys-wrapper/widget_wrapper';

import { SURVICATE_WORKSPACE_KEY } from '../constants/env';

// feedback is 'enabled' when its set
const FEEDBACK_ENABLED = !!SURVICATE_WORKSPACE_KEY;

// feedback is 'ready' after its been initialized
let FEEDBACK_READY = false;

export const initialize = (
  surveyDisplayCallback: (surveyId: string) => void,
  surveyCompletedCallback: (surveyId: string) => void
) => {
  if (FEEDBACK_ENABLED && !FEEDBACK_READY) {
    initSurvicate({ workspaceKey: SURVICATE_WORKSPACE_KEY }).then(
      () => {
        // attach events feedback
        const survicate = getSurvicateInstance();
        if (survicate) {
          survicate.addEventListener(
            ApiEvent.surveyDisplayed,
            surveyDisplayCallback
          );
          survicate.addEventListener(
            ApiEvent.surveyCompleted,
            surveyCompletedCallback
          );
          // we are ready for feedback
          FEEDBACK_READY = true;
        }
      },
      () => {
        // error
      }
    );
  }
};

export const showSurvey = (id: string, options?: GeneralObject) => {
  if (FEEDBACK_READY) {
    const survicate = getSurvicateInstance();
    if (survicate) {
      survicate.showSurvey(id, options);
    }
  }
};

export const setVisitorTraits = (options: GeneralObject) => {
  if (FEEDBACK_READY) {
    const survicate = getSurvicateInstance();
    if (survicate) {
      survicate.setVisitorTraits(options);
    }
  }
};
