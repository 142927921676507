// This must be the first line in src/index.js
import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import React from 'react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeFeedback } from './feedback';
import { initializeFeatureFlags } from './featureflags';
import {
  BUILD_TARGET,
  BUILD_TARGET_IS,
  API_ENV_IS,
  HOMEWARD_APEX_URL
} from './constants/env';
import { initializeAnalytics } from './analytics';

function importBuildTarget() {
  if (BUILD_TARGET_IS.CORE) {
    return import('./apps/core');
  } else if (BUILD_TARGET_IS.APEX) {
    return import('./apps/apex');
  } else {
    return Promise.reject(new Error('No such build target: ' + BUILD_TARGET));
  }
}

// we'll handle the app.buywithcash redirect to join.homeward redirect
if (BUILD_TARGET_IS.APEX && API_ENV_IS.PRODUCTION) {
  if (window.location.origin !== HOMEWARD_APEX_URL) {
    window.location.href = window.location.href.replace(
      window.location.origin,
      HOMEWARD_APEX_URL
    );
  }
}

// initialize tools
initializeAnalytics();
initializeFeedback();
initializeFeatureFlags();

const container = document.getElementById('root');
const root = createRoot(container as Element);

importBuildTarget().then(({ default: App }) => {
  root.render(<App />);
});

// we won't user service worker
// learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();
