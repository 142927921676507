import { GeneralObject } from 'types/index';
import { captureEventForAnalytics } from 'analytics';

import {
  initialize as initializeSurvicate,
  showSurvey as showSurveySurvicate,
  setVisitorTraits as setVisitorTraitsSurvicate
} from './survicate';

// development tooling
const STORYBOOK_RUNNING = process.env.STORYBOOK;
const JEST_RUNNING =
  process.env.JEST_WORKER_ID !== undefined || process.env.NODE_ENV === 'test';

// don't enable feedback for development tooling
const FEEDBACK_ENABLED = !STORYBOOK_RUNNING && !JEST_RUNNING;

// tracking events
const FEEDBACK_SURVEY_EVENT = {
  DISPLAYED: 'FeedbackSurveyDisplayed',
  COMPLETED: 'FeedbackSurveyCompleted'
} as const;

const trackSurveyDisplayed = (id: string) => {
  captureEventForAnalytics(FEEDBACK_SURVEY_EVENT.DISPLAYED, { id });
};
const trackSurveyCompleted = (id: string) => {
  captureEventForAnalytics(FEEDBACK_SURVEY_EVENT.COMPLETED, { id });
};

// intialize
export const initializeFeedback = () => {
  if (FEEDBACK_ENABLED) {
    initializeSurvicate(
      (surveyId: string) => {
        trackSurveyDisplayed(surveyId);
      },
      (surveyId: string) => {
        trackSurveyCompleted(surveyId);
      }
    );
  }
};

export const showSurveyForFeedback = (id: string, options?: GeneralObject) => {
  if (FEEDBACK_ENABLED) {
    showSurveySurvicate(id, options);
  }
};

export const setVisitorTraitsForFeedback = (options: GeneralObject) => {
  if (FEEDBACK_ENABLED) {
    setVisitorTraitsSurvicate(options);
  }
};
