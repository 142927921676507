import { FEATURE_FLAG_CONFIG } from 'constants/env';

import { FEATURE_FLAGS_QUERY, FEATURE_FLAGS, FEATURE_NAME } from './constants';

export { FEATURE_NAME };

/**
 * Initialize the feature flags within the application
 */
export const initializeFeatureFlags = (): void => {
  const featureFlagsFromURL =
    new URLSearchParams(window.location.search).get(FEATURE_FLAGS_QUERY) || '';

  (Object.keys(FEATURE_FLAGS) as FEATURE_NAME[]).forEach(flagName => {
    if (typeof FEATURE_FLAGS[flagName] !== 'undefined') {
      FEATURE_FLAGS[flagName] =
        FEATURE_FLAGS[flagName] ||
        FEATURE_FLAG_CONFIG[flagName] ||
        featureFlagsFromURL.includes(flagName);
    }
  });
};

/**
 * Checks if feature is enabled
 */
export const isFeatureEnabled = (featureName: FEATURE_NAME): boolean => {
  return !!FEATURE_FLAGS[featureName];
};
